@font-face {
  font-family: aeonik;
  src: url('https://cdn.demo.okta.com/fonts/aeonik-regular.woff2') format('woff2');
}

html{
  background-color: #F4F4F4;
}

body {
  margin: 0;
}